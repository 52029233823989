<template>
<div class="ser-wrap">
   <div class="ser-ask">
      <div class="info">
            <div class="l-box">
              <div class="detail1"><span class="content1">服务咨询：</span>400-1155-282</div>
              <div class="detail"><span>地址：</span> <span>北京市石景山区永引渠南路18号北明软件园A座4层</span> </div>
              <div class="detail">服务支持：public@fengtiaolishun.com</div>
            </div>
            <div class="r-box"  v-if="type == 'all'">
              <div class="img-box">
                 <img src="../../assets/home/fwh.jpeg" alt  class="part-four-img"/>
                 <p>关注枫调理顺公众号</p>
              </div>
              <div class="img-box">
                  <img src="../../assets/home/zxfw.jpeg" alt  class="part-four-img"/>
                  <p>关注至信链公众号</p>
              </div>
              <div class="img-box">
                  <img src="../../assets/home/jgfwh.jpeg" alt  class="part-four-img"/>
                  <p>关注预付监管公众号</p>
              </div>
            </div>

              <div class="r-box" v-if="type == 'shzh'">
                 <div class="img-box">
                  <img src="../../assets/home/xcx.jpeg" alt  class="part-four-img"/>
                  <p>使用预付监管小程序</p>
                 </div>

              <div class="img-box">
                 <img src="../../assets/home/jgfwh.jpeg" alt  class="part-four-img"/>
                 <p>关注预付监管公众号</p>
              </div>
             
             
            </div>
      </div>
      <div class="server-num"></div>
  </div>
</div>
</template>

<script>
export default {
  name: "SerAsk",
  methods: {},
  data(){
    return{
       type:'all', // 区分 all默认 shzh社会治理
    }
  },
  watch:{
  '$route.path':function(to,from){
      if(to == '/coreBusiness/SocialGovernance'){ 
         // 社会治理
        this.type ='shzh'
      }else{
        this.type ='all'
      }
    }
  },
};
</script>

<style lang='scss' scoped>
.ser-wrap{
  background: #F4F7FB;
  border-bottom: 0.01rem solid  #E9EFF6;
}
.ser-ask{
  width: 14.32rem;
   height: 2.80rem;
   margin:0 auto;
   background: #F4F7FB;
    .info{
      display: flex;
      .l-box{
        width:4.85rem;
        margin-right:2.60rem;
      }
      .r-box{
        display: flex;
        flex:1;
        justify-content: space-around;
        width:100%;
        padding-top:0.62rem;
        padding-bottom:0.62rem;
        .img-box{
          text-align: center;
          display: flex;
          flex-direction: column;
          align-items: center;
          img{
            width:1.09rem;
            height:1.10rem;
          }
          .part-four-img{
            margin-bottom: 0.04rem;
            margin-bottom: 15px;
          }
          p{
            font-size: 0.16rem;
            color: #3A4350;
          }
        }
      }
      .detail{
        font-family: PingFangSC-Regular;
        font-weight: 400;
        font-size: 0.18rem;
        color: #3A4350;
        line-height:0.36rem;
      }
      .detail1{
        font-size:0.22rem;
        color:#3A4350;
        font-weight: 600;
        padding-top:0.79rem;
        padding-bottom:0.36rem;
        .content1{
          font-family: PingFangSC-Semibold;
          font-weight: 600;
          color: #3A4350;
          font-size:0.20rem;
        }
        span{
          font-size:0.22rem;
        }

      }
    }
    .server-num{}
}
</style>