<template>
  <div class="footer">
    <p>
      Copyright© 2021 All Rights Reserved | 版权所有：北京枫调理顺科技发展有限公司 |
      <a
        target="_blank"
        href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010702002037"
      >京公网安备11010702002037号</a>

      <a
        target="_blank"
        href="https://beian.miit.gov.cn/"
      > 京ICP备19056263号-5</a>
      
    </p>
  </div>
</template>

<script>
export default {
  name: "AppFooter",
  methods: {}
};
</script>

<style lang='scss' scoped>
// .footer {
//   background: #F4F7FB;
//   text-align: center;
//   height: 60px;
//   line-height: 60px;
//   color: #3A4350 !important;
//   font-size: 14px;
//   p{
//     margin:0;
//   }
// }
.footer {
  background-color: #142957;
  text-align: center;
  height: 40px;
  line-height: 40px;
  color: #ffffff;
  font-size: 14px;
  p {
    a:link,
    a:visited,
    a:hover,
    a:active {
      color: #ffffff;
      font-size: 14px;
      text-decoration: none;
    }
  }
}

</style>
