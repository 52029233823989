<template>
  <div class="nav" ref="nav" :class="{ navMove: isMove }">
    <div class="nav-c w1200">
      <div class="header-logo" @click="goHome">
        <img src="@/assets/home/logo.png" alt="" />
      </div>
      <div class="menu-part">
        <el-menu
          :default-active="$route.path"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelect"
        >

        <el-menu-item index="/" >首页</el-menu-item>
        <el-submenu index="/CoreBusiness" popper-class="popperSubmenu" class='dropDown'>
            <template slot="title">核心业务</template>
            <el-menu-item index="/coreBusiness/SocialGovernance">社会治理</el-menu-item>
            <el-menu-item><a href="https://www.zxinchain.com/" target="_blank">至信存证</a></el-menu-item>
            <el-menu-item index="/PersonTrain">人才培养</el-menu-item>
            <el-menu-item index="/Solution" class="mb20">多元解纷</el-menu-item>
        </el-submenu>
        <el-menu-item index="/ComDynamics">公司动态</el-menu-item>
        <el-menu-item index="/AboutUs">关于我们</el-menu-item>
        </el-menu>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Nav",
  data() {
    return {
      userData: {},
      exist: 0,
      loginInfo: {
        email: "",
      },
      isMove: false,
      activeIndex: "/",
      isMove: false,
      menuData: [
        { path: "/", name: "首页" },
        { path: "/solution", name: "解决方案" },
        { path: "/browser", name: "证据校验" },
        { path: "/bigScreen", name: "数据大屏" },
        { path: "/accessDocuments", name: "文档中心" },
        { path: "/about", name: "关于我们" },
        { path: "/openSkill", name: "服务平台", icon: "icon-openlogo" },
      ],
    };
  },
  computed: {
   
  },

  methods: {
    goHome(){
        this.$router.push({
            path: "/",
          });
    },
    handleSelect(key, keyPath) {

      console.log("key",key)
        this.$router.push({
            path: key,
          });
          window.scrollTo(0, 0);


      //判断有没有登录 登陆之后百宝箱 服务平台 钱包时 需要单独打开标签页，bug:ID1000519
      // if (key == "/bigScreen") {
      //   const { href } = this.$router.resolve({
      //     name: `BigScreen`,
      //   });
      //   window.open(href, "_blank");
      // } else if (key === "/wallet/overView") {
      //   //  window.open(window.portal_redirectUrl.wallet, "_self");
      //   window.open(window.portal_redirectUrl.wallet, "_blank");
      // } else {
      //   if (this.$store.state.isLogin) {
      //     if (key === "/treasureBox") {
      //       window.open(
      //         `${window.portal_redirectUrl.treasurechest}treasurebox`,
      //         "_blank"
      //       );
      //     } else if (key === "/openSkill") {
      //       window.open(
      //         `${window.portal_redirectUrl.open}/hashcertificate/overview`,
      //         "_blank"
      //       );
      //     } else {
      //       this.$router.push({
      //         path: key,
      //       });
      //     }
      //   } else {
      //     this.$router.push({
      //       path: key,
      //     });
      //   }
      //   window.scrollTo(0, 0);
      // }
    },
  },
  created() {

   
  },
  mounted() {
    window.addEventListener("scroll", () => {
      // console.log("window.pageYOffset",window.pageYOffset)
      if (window.pageYOffset > 100) {
        this.isMove = true;
      } else {
        this.isMove = false;
      }
    });
  
  },
};
</script>

<style lang="scss">
.down-load-nav {
  min-width: 1.1rem !important;
  width: 1.1rem;
}
</style>

<style lang="scss" scoped>

.w1200 {
    width: 14.32rem!important;
    margin: 0 auto!important;
    /* border: 1px solid red; */
}
.down-load-nav {
  .btn {
    width: 1rem;
    min-width: 1rem !important;
  }
  .mb5 {
    margin-bottom: 5px;
  }
}
.tc {
  text-align: center;
}
.headLogo {
  margin-right: 5px;
}
.nav::after{
  filter: blur(5px);
}
.nav {
  justify-content: center;
  width: 100%;
  min-width: 1432px;
  position: fixed;
  left: 0;
  top: 0;
  transition: all 0.5s linear;
  z-index:40;
  right: 0;
  border: 1px solid #CAD8FB;
  backdrop-filter: blur(15px);
  //  background-color: #858995;
   background: rgba(255,255,255,0.10);
   height:0.80rem;
  .nav-c {
    display: flex;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .header-logo {
    // margin-top: 8px;
    z-index: 100 !important;
    display: flex;
    align-items: center;
    cursor: pointer;
    img {
      width:1.49rem;
      height:0.49rem;
      float: right;
    }
    .icon-size {
      color: #fff;
      float: right;
      font-size: 40px;
    }
  }
  /deep/.menu-part {
    // width:75%;
    width:5.12rem;
    z-index: 10002;
    .el-menu--horizontal>.el-submenu .el-submenu__title:hover{
      background-color: transparent;
    }
    .el-menu--horizontal>.el-submenu .el-submenu__icon-arrow{
      display: none;
    }
    .el-menu-demo {
      border: none;
      background-color: transparent;
      font-family: PingFangSC-Semibold;
       display: flex;
       justify-content: space-between;
      .el-menu-item {
        color: #3E3E3E;
        border: 2px solid transparent;
        font-size: 0.16rem;
        padding: 0 18px;
        font-weight: 400;
        height:0.80rem;
        line-height: 0.80rem;


      }
      .el-submenu{
        .el-submenu__title{
             color: #3E3E3E !important;
             font-size: 0.16rem !important;
              height:0.80rem;
        line-height: 0.80rem;
        }

      }
      .nav-icon {
        color: #bacff6;
        font-size: 20px;
        vertical-align: -1px;
        margin-right: 2px;
      }
      .login-nav-icon {
        color: #bacff6;
        font-size: 16px;
        vertical-align: -1px;
        margin-right: 3px;
      }
      .el-menu-item:not(.is-disabled):focus,
      .el-menu-item:not(.is-disabled):hover {
        background-color: transparent;
      }
      .el-menu-item.is-active {
        font-weight: 600;
        border-bottom: 2px solid #0334B8;
        color: #0334B8;
      }
    }
  }
}
.navMove {
  // background-color: #fff;
  transition: all 0.5s linear;
  backdrop-filter: blur(15px);
    background-color: rgba(255, 255, 255, 0.5);
}
.icon-color {
  color: #bacff6;
  font-size: 20px;
}
.email{
  margin-left:5px;
  color:rgba(255, 255, 255, 0.5)
}

.dropDown{
  padding-top:2px !important;
  

}


.popperSubmenu{
  display: block !important;  



  .el-menu-item{
    background: #FFFFFF  !important;
    color: #1C1C1C  !important;
    text-align: center !important;
    font-size:0.16rem !important;
    font-family: PingFangSC-Regular !important;
    margin-top:0.15rem !important;
    font-weight: 400;
    a{
      text-decoration: none;
      color: #1C1C1C;
    }
    a:hover{
       color:#002DBB!important; 
       font-weight: 500;
    }

  }

  .el-menu-item.is-active{
    background-color: #136EFF !important;
    color:#fff!important; 
  }

   .el-menu-item:hover{
     background-color: #136EFF !important;
     color:#fff!important; 
     font-weight: 500;
   }
}


  /deep/.el-menu--horizontal>.el-submenu.is-active .el-submenu__title{
    border-bottom: 2px solid #0334B8 !important;
  }

 

  .mb20{
    margin-bottom: 0.10rem;
  }





</style>

<style>
  .el-menu--collapse .el-menu .el-submenu, .el-menu--popup{
  min-width: 136px!important;
margin-left:-16px !important;
}
</style>

