<template>
  <div class="title-c">
    <div class="content">
      <div :class="{'name': flag1, 'titleColor': flag}">{{content.name}}</div>
      <div :class="{'eg-name': flag1, 'titleColor': flag}">{{content.egName}}</div>
      <!-- <div class="line"></div> -->
    </div>
  </div>
</template>
<script>
export default {
  props: ["content", "flag"],
  data() {
    return {
      flag1: true
    };
  }
};
</script>

<style lang="scss" scoped>
.title-c {
  padding-top: 0.60rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  .content {
    position: relative;
    display: flex;
    flex-direction: column;
      justify-content: center;
  align-items: center;
    width: 80%;
    // border-bottom: 1px solid #DEDEDE;
    text-align: center;
    .name {
       font-family: PingFangSC-Medium;
      font-weight: 500;
      font-size: 0.36rem;
      color: #1C1C1C;
    }
    .eg-name {
      padding: 0 0 0.40rem 0;
      letter-spacing:0.01rem;
      font-family: PingFangSC-Medium;
      font-weight: 500;
      font-size: 0.14rem;
      color: #BFC7D1;
    }
//    .line{
//          position: absolute;
//     top: 73rem;
// width:82rem;
// height:5rem;
// background-color: #3478E3;
//    }
  }
}
</style>
