<template>
    <div class="header" :class="[going?'going':'',backgroundUrl]">
      <div class="theme">
        <div class="title">{{ title }}</div>
        <div class="dec" v-if="describe">{{ describe }}</div>
        <div class="dec" v-if="vhtml" v-html="vhtml"></div>
      </div>
    </div>
</template>
<script>
export default {
  name: "HeaderStyle",
  props: {
    title: {
        type: String,
        default:""
    },
    describe: {
         type: String,
        default:"" 
    },
    backgroundUrl: {
        type: String,
        default:""
    },
    vhtml:{
      type:String,
      default:''
    }
  },
  data() {
    return {
      going: false,
    };
  },
    mounted() {
    console.log(this.backgroundUrl)
    this.going = true;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/common.scss"
</style>