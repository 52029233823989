<template>
    <div class="page-box">
        <div>
            <Nav></Nav>
            <HeaderStyle :title="title" :describe="describe" :backgroundUrl="backgroundUrl"></HeaderStyle>
        </div>
        <div class="solution-box">
          <div class="part-1-bg">
                <div class="part-1">
                <Title :content="content1"></Title>
                <div class="border-wrap">
                    <div class="t-box">
                       <img src="@/assets/solution/sbg1.png" alt="" />
                       <p>我司不断加快矛盾解纷一体化信息平台研发的步伐，以需求为导向，在平台的整体设计理念彰显网络化、阳光化、智能化，利用区块链、人工智能、大数据等信息化技术，打造至信云审云调系统，具备批量存证、智能催告、要素式调解、示范性庭审撮合、批量申请执行等多种服务功能，将矛盾纠纷逐级分层过滤，借助专家调解团队运用要素式调解、审判理念，形成漏斗式的矛盾纠纷解决模式，快速化解纠纷，助力行政机关监管、法院调解工作取得实效。</p>
                    </div>
                    <div class="b-box">
                        <div v-for="(item,index) in models" :key="index" class="b-box-style">
                            <img :src="item.imgUrl" alt="">
                            <p class="p-name">{{item.text}}</p>
                        </div>
                    </div>
                </div>
            </div>
          </div>
          <div>
            <div class="part-2">
                <Title :content="content2"></Title>
                <div class="part-2-bg">
                    <img class="part-2-bg-img" src="@/assets/solution/sbg2.png" alt="">
                </div>
            </div>
          </div>

        </div>
    </div>
</template>
<script>
import AppFooter from '@/components/layer/AppFooter'
import SerAsk from '@/components/layer/SerAsk'
import HeaderStyle from '@/components/commom/HeaderStyle'
import Nav from "@/components/layer/HeaderNav";
import Title from "@/components/layer/OneTitle.vue";
export default {
    name:' Solution',
     metaInfo:{
      title: '枫调理顺-社会治理与纠纷解决方案商', //设置页面title
      meta: [
        {                 //设置页面meta
        name: 'keyWords',  
        content: '下架函,调解维权,视频侵权监测,图片侵权监测,文本侵权监测,版权,版权登记,抄袭,抄袭维权,原创抄袭,洗稿,内容复用,作者,内容维权,内容创作,抄袭判定,抄袭判定标准'
        }
      ]
  },
    components: {
    AppFooter,
    SerAsk,
    HeaderStyle,
    Nav,
    Title
  },
  data(){
      return {
        title:"多元解纷",
        backgroundUrl:"backgroundUrl",
        describe:"我司融合法律、科技及调解业务能力，运用区块链存证技术、要素式解纷一体化方案，在预付卡消费领域、金融领域、知识产权领域、合规管理等多领域开展多元解纷工作。",
        content1: {
            name: "至信云调平台及漏斗式解纷模式",
            egName: "ZHI XIN CHAIN PLATFORM AND ODR MODE"
        },
         content2: {
            name: "解纷流程",
            egName: "DISPUTE RESOLUTION PROCESS"
        },
        models:[
            {
                imgUrl: require("@/assets/solution/patten1.png"),
                text:"批量存证"
            },
             {
                imgUrl: require("@/assets/solution/patten2.png"),
                text:"要素式调解"
            },
             {
                imgUrl: require("@/assets/solution/patten3.png"),
                text:"示范性庭审撮合"
            },
              {
                imgUrl: require("@/assets/solution/patten4.png"),
                text:"批量执行"
            },
        ]
      }
  }
}
</script>

<style lang="scss" scoped>
.page-box{
    background: #F8FBFF;
    .solution-box{
      
    }
    .border-wrap{
        border: 1px solid rgba(191,199,209,0.50);
    }
    .part-1-bg{
        background: #fFf;
        padding-bottom:0.5rem;
    }
    .part-1{
        width: 14.32rem;
        margin:0 auto;
        background: #fFf;
    }
    .part-2{
        width: 14.32rem;
        margin:0 auto;
        background: #F8FBFF;
        padding-bottom:0.40rem;
        .part-2-bg{
            background: #fff;
            padding:0.40rem 0.30rem;
            .part-2-bg-img{
                width:100%;
                }
        }
    }
    .t-box{
        display: flex;
        img{
           width:9.24rem;
           height: 4rem;
        }
        p{
            width: 4.54rem;
            height: 3.63rem;
            font-family: PingFangSC-Regular;
            font-size: 0.18rem;
            color: #1C1C1C;
            line-height: 0.3rem;
            margin-left:0.28rem;
            padding-top:0.36rem;
            text-indent: 0.20rem;
        }
    }
    .b-box{
        display: flex;
        .b-box-style{
            flex:1;
            height:1.80rem;
            text-align: center;
            padding:0.36rem 0;
            box-sizing: border-box;
            font-family: PingFangSC-Medium;
            font-weight: 500;
            font-size: 0.18rem;
            color: #1C1C1C;
            line-height: 0.18rem;

        }
        // .b-box-style:nth-child(odd){

        // }
          .b-box-style:nth-child(even){
            background: #F1F6FF
        }
        .p-name{
            font-family: PingFangSC-Medium;
            font-weight: 500;
            font-size: 0.18rem;
            color: #1C1C1C;
            padding-top:0.20rem;
        }
    }
}
.backgroundUrl{
    background-image:url("../../assets/solution/sbg3.png");
    background-size:100% 100%;
}
</style>
